import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTalleres(ctx, codigo) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/global`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTaller(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-deportivo/taller-global/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTaller(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${data.get('codigo')}/global`, data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    updateTaller(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${data.get('codigo')}/global/${data.get('id')}`, data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    deleteTaller(ctx, { codigo, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-cultural-deportivo/${codigo}/global/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
