<template>
  <div>
    <b-modal
      id="modal_nuevo_taller_global"
      ref="modal"
      :title="tituloModal"
      hide-footer
    >
      <form
        ref="form"
        @submit.prevent="GuardarEditar"
      >
        <b-form-group
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <label v-if="codigo === 'OCADE-ALT-COMP'">Nombre del Programa Deportivo</label>
          <label v-else>Nombre del Taller Global</label>
          <b-form-input
            id="name-input"
            v-model="tallerNuevo.nombre"
            required
          />
          <label v-if="codigo === 'OCADE-ALT-COMP'">Descripción del Programa(opcional)</label>
          <label v-else>Descripción del Taller(opcional)</label>

          <b-form-textarea
            id="textarea"
            v-model="tallerNuevo.descripcion"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          />
          <label for="">Cargar archivo tipo imagen</label>

          <b-form-file
            v-model="tallerNuevo.img"
            accept="image/*"
            :required="guardarEditar"
          >
            <template
              slot="file-name"
              slot-scope="{ names }"
            >
              <b-badge variant="primary">
                {{ names[0] }}
              </b-badge>
              <b-badge
                v-if="names.length > 1"
                variant="dark"
                class="ml-1"
              >
                + {{ names.length - 1 }} More files
              </b-badge>
            </template>
          </b-form-file>
        </b-form-group>
        <div class="w-100">
          <b-button
            variant="outline-success"
            class="float-right"
            type="submit"
          >
            {{ tituloBoton }} <feather-icon icon="SaveIcon" />
          </b-button>
        </div>
      </form>

    </b-modal>

  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BFormTextarea, BFormFile, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BBadge,
  },
  props: {
    codigo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tituloModal: '',
      tituloBoton: '',
      tallerNuevo: { nombre: '', descripcion: '', img: null },
      guardarOeditar: '',
      guardarEditar: false,
    }
  },
  created() {
    this.$parent.$on('abrirModalNuevoTallerglobal', this.abrirModalNuevoTallerglobal)
    this.$parent.$on('abrirModalEditarTallerGlobal', this.abrirModalEditarTallerGlobal)
  },
  methods: {
    abrirModalNuevoTallerglobal() {
      if (this.codigo === 'OCADE-ALT-COMP') {
        this.tituloModal = 'Nuevo Programa Deportivo Global'
      } else {
        this.tituloModal = 'Nuevo Taller Global'
      }
      this.tituloBoton = 'Guardar'
      this.guardarEditar = true
      this.tallerNuevo = {}
      this.tallerNuevo.nombre = ''
      this.tallerNuevo.descripcion = ''
      this.tallerNuevo.img = null
      this.$bvModal.show('modal_nuevo_taller_global')
    },
    abrirModalEditarTallerGlobal(value) {
      if (this.codigo === 'OCADE-ALT-COMP') {
        this.tituloModal = 'Editar Programa Deportivo Global'
      } else {
        this.tituloModal = 'Editar Taller Global'
      }
      this.tituloBoton = 'Actualizar'
      this.guardarEditar = false
      this.tallerNuevo.id = value.id
      this.tallerNuevo.nombre = value.nombre
      this.tallerNuevo.descripcion = value.descripcion === 'null' ? '' : value.descripcion
      this.tallerNuevo.img = null
      this.$bvModal.show('modal_nuevo_taller_global')
    },
    GuardarEditar() {
      if (this.guardarEditar) { // true = guardar
        const formdata = new FormData()
        formdata.append('codigo', this.codigo)
        formdata.append('nombre', this.tallerNuevo.nombre)
        formdata.append('descripcion', this.tallerNuevo.descripcion)
        formdata.append('img', this.tallerNuevo.img)

        store.dispatch('globalStoreModule/addTaller', formdata).then(() => {
          this.$swal({
            position: 'top-end',
            icon: 'success',
            title: 'Taller registrado!!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$emit('cargarTalleres')
          this.$bvModal.hide('modal_nuevo_taller_global')
        }).catch(error => {
          if (error.response.data.code >= 400 && error.response.data.code <= 499) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '¡Advertencia!',
                text: error.response.data.error,
                icon: 'XIcon',
                variant: 'warning',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar los talleres (500)!',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
      } else { // false = actualizar
        const formdata = new FormData()
        formdata.append('id', this.tallerNuevo.id)
        formdata.append('codigo', this.codigo)
        formdata.append('nombre', this.tallerNuevo.nombre)
        formdata.append('descripcion', this.tallerNuevo.descripcion)
        formdata.append('img', this.tallerNuevo.img)
        formdata.append('_method', 'PUT')

        store.dispatch('globalStoreModule/updateTaller', formdata).then(() => {
          this.$swal({
            position: 'top-end',
            icon: 'success',
            title: 'Taller actualizado!!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$emit('cargarTalleres')
          this.$bvModal.hide('modal_nuevo_taller_global')
        }).catch(error => {
          if (error.response.data.code >= 400 && error.response.data.code <= 499) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '¡Advertencia!',
                text: error.response.data.error,
                icon: 'XIcon',
                variant: 'warning',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar los talleres (500)!',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
  },
}
</script>
